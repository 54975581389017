<template>
  <ion-page>
    <ion-header>
      <LoggedInNav
        :title="'Datos Personales'"
        :back="true"
        :backUrl="'/configuracion'"
      />
    </ion-header>
    <ion-grid class="grid">
      <ion-row class="w-100">
        <ion-col>
          <div class="centerCenter" style="margin-top: 10px">
            <div class="whiteDivNext" v-on:click="gotoNombre">
              <table width="100%">
                <tr>
                  <td width="50%" align="left">
                    <div style="height: 10px">&nbsp;</div>
                    <span> {{ fullName }} </span>
                  </td>
                  <td width="50%" align="end">
                    <ion-icon
                      :icon="chevronForwardOutline"
                      style="color: #d46827; font-size: 25px; margin-top: 10px"
                    ></ion-icon>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="w-100">
        <ion-col>
          <div class="centerCenter" style="margin-top: 50px">
            <div class="whiteDivNext" v-on:click="gotoEmail">
              <table width="100%">
                <tr>
                  <td width="50%" align="left">
                    <div style="height: 10px">&nbsp;</div>
                    <span> {{ email }} </span>
                  </td>
                  <td width="50%" align="end">
                    <ion-icon
                      :icon="chevronForwardOutline"
                      style="color: #d46827; font-size: 25px; margin-top: 10px"
                    ></ion-icon>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="w-100">
        <ion-col>
          <div class="centerCenter" style="margin-top: 50px">
            <div class="whiteDivNext" v-on:click="gotoContrasena">
              <table width="100%">
                <tr>
                  <td width="50%" align="left">
                    <div style="height: 10px">&nbsp;</div>
                    <span>Contraseña</span>
                  </td>
                  <td width="50%" align="end">
                    <ion-icon
                      :icon="chevronForwardOutline"
                      style="color: #d46827; font-size: 25px; margin-top: 10px"
                    ></ion-icon>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="grid__actions">
        <ion-col>
          <div class="centerCenter" style="margin-bottom: 50px; margin-top:20px">
            <span class="parrafo-small">
              <span
                style="color: #e33613; font-weight: bold; letter-spacing: 2px"
                v-on:click="goToEliminar"
                >ELIMINAR CUENTA</span
              >
            </span>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonCol,
  IonGrid,
  IonRow,
  IonHeader,
  IonIcon
} from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
import LoggedInNav from "../../../components/LoggedInNav.vue";
import router from "../../../router/index";
import { useStore } from "vuex";

export default defineComponent({
  name: "DatosPersonales",
  components: {
    IonPage,
    IonCol,
    IonGrid,
    IonRow,
    IonHeader,
    LoggedInNav,
    IonIcon
  },
  data() {
    return {};
  },
  computed: {
    fullName() {
      return this.$store.getters["User/getFullName"];
    },
    email() {
      return this.$store.getters["User/getEmail"];
    },
  },
  methods: {
    gotoNombre() {
      router.push("/configuracion/datosPersonales/nombre");
    },
    gotoEmail() {
      router.push("/configuracion/datosPersonales/email");
    },
    gotoContrasena() {
      router.push("/configuracion/datosPersonales/contrasena");
    },
    goToEliminar() {
      router.push("/configuracion/eliminarCuenta");
    },
  },
  setup() {
    const store = useStore();
    console.log(store.getters["User/getFullName"]);
    return {
      chevronForwardOutline,
    };
  },
});
</script>
<style lang="scss" scoped>
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 38px 14px 35px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }

  &__actions {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
  }
}

.whiteDivNext {
  background-color: white;
  height: 50px;
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #b4b4b4;
  padding: 0px 15px 5px 15px;
  font-family: "Avenir";
  color: #b4b4b4;
  width: 100%;
  max-width: 700px;
}
</style>
